<template>
  <div class="ClientScreen">
    <div id="map" style="width: 100%;height:100%"></div>
    <div class="left-container">
      <transition name="show">
        <div class="left-main" v-show="leftShow">
          <div class="left-header">
            <a-icon type="tag" class="ant-menu-item-selected icon" />
            <span>员工疫情情况筛选</span>
          </div>
          <div class="content">
            <div class="row">
              <span>选择员工</span>
              <div>
                <person v-bind="personObj" @getData="_getPerson"></person>
              </div>
            </div>

            <div class="row">
              <span>健康情况</span>
              <div>
                <a-select style="width: 100%" v-model="condition1">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="良好">良好</a-select-option>
                  <a-select-option value="一般">一般</a-select-option>
                  <a-select-option value="异常">异常</a-select-option>
                </a-select>
              </div>
            </div>

            <div class="row">
              <span>亲属情况</span>
              <div>
                <a-select style="width: 100%" v-model="condition2">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="良好">良好</a-select-option>
                  <a-select-option value="一般">一般</a-select-option>
                  <a-select-option value="异常">异常</a-select-option>
                </a-select>
              </div>
            </div>
            <div class="row" style="text-align: right;display: block">
              <a-button type="primary" @click="_search">开始查询</a-button>
            </div>
          </div>
        </div>
      </transition>
      <div
        class="cut-btn"
        @click="_cutShow"
        @mouseover.stop="_hintShow"
        @mouseout.stop="_hintShow"
      >
        <a-icon
          :type="leftShow ? 'caret-left' : 'caret-right'"
          style="color:#8A8A8A"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BMap from 'BMap'
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import person from 'components/SelectPersonnel/SelectPersonnel' //选人
import axios from 'axios'
const http = axios.create({
  baseURL: 'https://sapi.sikegroup.com',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  },
  transformRequest: [
    function (data) {
      let newData = ''
      for (let k in data) {
        if (data.hasOwnProperty(k) === true) {
          newData +=
            encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
        }
      }
      return newData
    }
  ]
})
export default {
  name: 'ClientScreen',
  components: {
    person
  },
  data () {
    return {
      list: [],
      visible: false,
      leftShow: true,
      hintMsg: false,
      SHOW_PARENT: SHOW_PARENT,
      treeData: [],
      personObj: {
        rank: 1, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: '', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      person: [],
      condition1: '',
      condition2: ''
    }
  },
  created () {},
  mounted () {
    this.$message.loading('查询中...', 0)
    http({
      method: 'get',
      url: '/user/v1/user_epidemic_list',
      params: {
        ParentId: this.person.length != 0 ? this.person[0].id : '',
        SelfHealth: this.condition1,
        FamilyHealth: this.condition2
      }
    }).then(res => {
      console.log(res.data.code)
      if (res.data.code == 1) {
        this.list = res.data.data.list
        this.createMap()
        this.$message.destroy()
      } else {
        this.createMap()
        this.$message.destroy()
      }
    })
  },
  methods: {
    _search () {
      this.$message.loading('查询中...', 0)
      http({
        method: 'get',
        url: '/user/v1/user_epidemic_list',
        params: {
          ParentId: this.person.length != 0 ? this.person[0].id : '',
          SelfHealth: this.condition1,
          FamilyHealth: this.condition2
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 1) {
          this.list = res.data.data.list
          this.createMap()
          this.$message.destroy()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    _getPerson (data) {
      this.person = data
    },
    _cutShow () {
      this.leftShow = !this.leftShow
      this.hintMsg = false
    },
    _addList (data) {
      console.log('addMK')
      var map = new BMap.Map('map') // 创建Map实例
      map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 5) //// 初始化地图,设置中心点坐标和地图级别
      //启用滚轮放大缩小
      if (document.createElement('canvas').getContext) {
        // 判断当前浏览器是否支持绘制海量点
        var points = [] // 添加海量点数据
        var points1 = []
        let icon = function (iconWidth, iconHeight, iconUrl) {
          let size = new BMap.Size(iconWidth, iconHeight)
          return new BMap.Icon(iconUrl, size)
        }
        for (var i = 0; i < data.length; i++) {
          let Location = data[i].Location.split(',')
          console.log(Location)
          let point = new BMap.Point(Location[0], Location[1])
          point.CreateUserName = data[i].CreateUserName
          point.DepartmentStr = data[i].DepartmentStr
          point.Addr = data[i].Addr
          point.SelfHealth = data[i].SelfHealth
          point.FamilyHealth = data[i].FamilyHealth
          point.KeyPostName = data[i].KeyPostName
          // points.push(point);
          // if(data[i].Sex==1){
          //     var point1 = new BMap.Point(Location[0],Location[1]);
          //     points1.push(point1);
          // }

          if (data[i].Sex == 1) {
            var marker = new BMap.Marker(point, {
              icon: icon(
                25,
                25,
                'https://smart-resource.sikegroup.com/nan.png?imageView2/1/w/20/h/20'
              )
            })
          } else {
            var marker = new BMap.Marker(point, {
              icon: icon(
                25,
                25,
                'https://smart-resource.sikegroup.com/nv.png?imageView2/1/w/20/h/20'
              )
            })
          }

          let openinfoData = `<div>
                                            <div>姓名:${data[i].CreateUserName}</div>
                                            <div style="font-size:12px;">电话: ${data[i].MovePhone}</div>
                                            <div style="font-size:12px;">部门:  ${data[i].DepartmentStr} </div>
                                            <div style="font-size:12px;">职位:  ${data[i].KeyPostName} </div>
                                            <div style="font-size:12px;">工作地:  ${data[i].Addr} </div>
                                            <div style="font-size:12px;">健康状况:  ${data[i].SelfHealth} </div>
                                            <div style="font-size:12px;">亲属健康状况:  ${data[i].FamilyHealth} </div>
                                            <div style="font-size:12px;">打卡时间:  ${data[i].CreateTime} </div>
                                       </div>
                                        `
          // 创建信息窗口对象
          let opts = {
            width: 100, // 信息窗口宽度
            height: 170, // 信息窗口高度
            title: '员工信息', // 信息窗口标题
            enableMessage: false //设置允许信息窗发送短息
          }
          marker.addEventListener('mouseover', function (e) {
            let p = e.target
            let scaleVal = map.getZoom()
            let point = new BMap.Point(
              p.getPosition().lng,
              p.getPosition().lat + 4.8 / Math.pow(2, scaleVal - 3)
            )
            map.openInfoWindow(new BMap.InfoWindow(openinfoData, opts), point)
          })
          map.addOverlay(marker)
        }
        var options = {
          size: 40,
          shape: BMAP_POINT_SHAPE_STAR,
          color: '#f00'
        }
        //BMAP_POINT_SHAPE_WATERDROP

        var pointCollection = new BMap.PointCollection(points, options) // 初始化PointCollection
        pointCollection.addEventListener('mouseover', function (e) {
          var point = new BMap.Point(e.point.lng, e.point.lat)

          var openinfoData = `<div>
                                            <div>姓名:${e.point.CreateUserName}</div>
                                            <div style="font-size:12px;">电话: 无</div>
                                            <div style="font-size:12px;">部门:  ${e.point.DepartmentStr} </div>
                                            <div style="font-size:12px;">职位:  ${e.point.KeyPostName} </div>
                                            <div style="font-size:12px;">工作地:  ${e.point.Addr} </div>
                                            <div style="font-size:12px;">健康状况:  ${e.point.SelfHealth} </div>
                                            <div style="font-size:12px;">亲属健康状况:  ${e.point.FamilyHealth} </div>
                                       </div>
                                        `
          var opts = {
            width: 100, // 信息窗口宽度
            height: 160, // 信息窗口高度
            title: '员工信息', // 信息窗口标题
            enableMessage: false //设置允许信息窗发送短息
          }
          var infowindow = new BMap.InfoWindow(openinfoData, opts)
          map.openInfoWindow(infowindow, point)
        })
        pointCollection.addEventListener('mouseout', function () {
          this.closeInfoWindow()
        })
        //男
        map.addOverlay(pointCollection)
        var options1 = {
          size: 40,
          shape: BMAP_POINT_SHAPE_STAR,
          color: '#00f'
        }
        var pointCollection1 = new BMap.PointCollection(points1, options1)
        map.addOverlay(pointCollection1)
        //开启鼠标滚轮缩放
        map.enableScrollWheelZoom(true)
      } else {
        alert('请在chrome、safari、IE8+以上浏览器查看本页面')
      }
      let geolocation = new BMap.Geolocation()
      let gc = new BMap.Geocoder() //创建地理编码器
      // 开启SDK辅助定位
      // geolocation.enableSDKLocation();
      // geolocation.getCurrentPosition(function(r){
      //     if(this.getStatus() == BMAP_STATUS_SUCCESS){
      //         map.panTo(r.point);
      //         var pt = r.point;
      //         map.panTo(pt);//移动地图中心点
      //         gc.getLocation(pt, function(rs){
      //             var addComp = rs.addressComponents;
      //         });
      //
      //     }
      //     else {
      //         alert('failed'+this.getStatus());
      //     }
      // });
    },
    createMap () {
      let self = this
      let map = new BMap.Map('map')
      map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 5) //// 初始化地图,设置中心点坐标和地图级别
      let geolocation = new BMap.Geolocation()
      let gc = new BMap.Geocoder() //创建地理编码器
      // 开启SDK辅助定位
      if (self.list.length != 0) {
        self._addList(self.list)
      }
      // geolocation.enableSDKLocation();
      // geolocation.getCurrentPosition(function(r){
      //     console.log('create3');
      //     if(this.getStatus() == BMAP_STATUS_SUCCESS){
      //         console.log(self.list.length);
      //
      //         map.panTo(r.point);
      //         var pt = r.point;
      //         map.panTo(pt);//移动地图中心点
      //         gc.getLocation(pt, function(rs){
      //             var addComp = rs.addressComponents;
      //         });
      //
      //     }
      //     else {
      //         alert('failed'+this.getStatus());
      //     }
      // });
      //开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true)
    }
  }
}
</script>

<style scoped lang="less">
.ClientScreen {
  height: 100%;
  position: relative;
  .left-container {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    height: 95%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
      0 1px 4px rgba(0, 0, 0, 0.08);
  }
  .left-main {
    width: 350px;
    height: 100%;
    padding: 20px 15px;
    padding-top: 10px;
    border-radius: 2px;
    .left-header {
      padding-bottom: 8px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      span {
        font-size: 16px;
        color: #666;
      }
      .icon {
        margin-right: 7px;
        font-size: 18px;
        vertical-align: middle;
      }
    }
  }
  .add-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
  }
  .cut-btn {
    position: absolute;
    top: 20px;
    right: -20px;
    width: 20px;
    height: 40px;
    line-height: 38px;
    background: #fff;
    border: 1px solid #e4e5e6;
    border-left: none;
    cursor: pointer;
    .hint-msg {
      position: absolute;
      top: 50%;
      right: -85px;
      transform: translateY(-50%);
      background: #000;
      opacity: 0.7;
      padding: 4px 8px;
      white-space: nowrap;
      font-size: 10px;
      line-height: 1.6;
      color: #fff;
      border-radius: 2px;
      .san {
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        border-right: 5px solid #000;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }
    }
  }
  .alert-top {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 8px 15px;
    padding-left: 10px;
    background: #fff;
    border-radius: 4px;
    div {
      margin-bottom: 5px;
    }
    div:last-child {
      margin-bottom: 0;
    }
    i {
      margin-right: 8px;
    }
    span {
      color: #848484;
    }
  }
  .row {
    padding-right: 10px;
    span {
      display: inline-block;
      width: 70px;
      white-space: nowrap;
      color: #666;
      sup {
        color: #ff4646;
      }
    }
    .suffix {
      display: inline-block;
      width: 12px;
      font-size: 12px;
    }
    > div {
      flex: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .list-title {
      font-size: 18px;
      /*font-weight:bold;*/
      color: rgba(102, 102, 102, 1);
    }
  }
}
.show-enter-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-leave-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-enter,
.show-leave-to {
  width: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
</style>
